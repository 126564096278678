$(document).ready(function() {
    // Login form
    $('#login-modal form').formValidation({
        locale: 'fr_FR',
        icon: {
            valid: 'glyphicon glyphicon-ok',
            invalid: 'glyphicon glyphicon-remove',
            validating: 'glyphicon glyphicon-refresh'
        },
        fields: {
            login: {
                validators: {
                    notEmpty: {},
                }
            },
            password: {
                validators: {
                    notEmpty: {}
                }
            }
        }
    });


    // Register form
    $('#register-modal form').formValidation({
        locale: 'fr_FR',
        icon: {
            valid: 'glyphicon glyphicon-ok',
            invalid: 'glyphicon glyphicon-remove',
            validating: 'glyphicon glyphicon-refresh'
        },
        fields: {
            name: {
                validators: {
                    notEmpty: {},
                    stringLength: {min:4}
                }
            },
            email: {
                validators: {
                    notEmpty: {},
                    emailAddress: {}
                }
            },


            password: {
                validators: {
                    notEmpty: {}
                }
            },
            password_confirmation: {
                validators: {
                    identical: {
                        field:"password"
                    }
                }
            }
        }
    });

});