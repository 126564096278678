$(function() {
  if(typeof couplageAjaxUrl == "undefined")
    return;


/*
 *
 * Populate selects when needed only for faster init
 *
 *
 */
function syncSelectOptions(data){
    return function(){
        var select = $(this).parent().parent().find('select');
        var options = select.find('option');
        if(options.length != data.length){
            for(var j = 0; j < data.length; j++){
                // Already exists ?
                var exists = false;
                for(var i = 0; !exists && i < options.length; i++)
                    exists = (data[j].id == options[i].value);

                if(exists)
                    continue;

                var option = document.createElement('option');
                option.value = data[j].id;
                option.appendChild(document.createTextNode(data[j].text));
                select.append(option);
            }

            select.trigger('chosen:updated');
        }
    }
};

if(typeof dataRefs != "undefined") $('.references .chosen-choices').on('click', syncSelectOptions(dataRefs));
if(typeof dataDevs != "undefined") $('.couverture .chosen-single' ).on('click', syncSelectOptions(dataDevs));



/*
 *
 * (AJAX) Commentaire modification for the couplage
 *
 *
 */
$("#couplage-commentaire").focus(function(){
    $("#couplage-commentaire-ok").css('left', '-30px');
});

$("#couplage-commentaire").blur(function(){
    $("#couplage-commentaire-ok").css('left', '0px');
    $.post(couplageAjaxUrl + '/commentaires', { type:'generaux', value: $(this).val() });
});





/*
 *
 * Developpements
 *
 */

// Generic function generator to handle developpement add/edit
// type = {add, edit, couverture}
function developpementsAction(type) {
return function(){
    var select, id, text, ajaxUrl, o = this, action;
    if(type == "add" || type == "couverture") {
        select = this.nextElementSibling;
        id     = select[select.selectedIndex].value;
        text   = select[select.selectedIndex].innerHTML;
        action = 'add';

        if(id == ''){
            alert("Champ vide.");
            return false;
        }
    }
    if(type == "edit") {
        id     = $(this).closest('li').attr('data-id');
        text   = $(this).closest('ul').find('option[value="'+ id +'"]').text();
        action = 'edit';
    }
    if(type == "addFromDev") {
        id     = $(this).attr('data-id');
        action = 'add';
    }

    $.post(couplageAjaxUrl + '/getRecasages', { 'id':id, 'type':type })
    .done(function(data){
        $('#modal-developpement').html(data);
        $('#modal-developpement').modal('show').on('shown.bs.modal', function () {
            $('.chosen-select', this).chosen();
        });

        // Enable rating and chosen select
        Rating.add($('#modal-developpement'));
        // Enable sumbit
        $('#modal-developpement .btn-primary').click(function(){
            $.post(couplageAjaxUrl + '/developpement', $('#modal-developpement form').serialize() + "&action=" + action)
            .done(function(data){
                $('#modal-developpement').html('');
                $('#modal-developpement').modal('hide');

                if(type == "addFromDev")
                    location.reload();

                if( type == "add"
                || (type == "couverture" && $('#list-developpements input[name="developpements['+id+']"]').length == 0))
                    addDeveloppement(id, text, data);

                updateDeveloppement(id, text, data);
            });
        });
    });
};
}

$('#list-developpements .developpement-add-btn').click(developpementsAction('add'));
$('#list-developpements .developpement-edit').click(developpementsAction('edit'));

$('.lecon .couverture-add-btn').click(developpementsAction('couverture'));
$('.lecon .developpement-edit').click(developpementsAction('edit'));

$('#developpement .developpement-add-btn').click(developpementsAction('addFromDev'));

/*
 *
 * Add a developpement in the list
 *
 */
function addDeveloppement(id, text, data){
    if($('#list-developpements').length == 0)
        return;
    if($('#list-developpements li[data-id="'+id+'"]').length > 0)
        return;

    var li = $('<li>', { class:'list-group-item developpement-item', 'data-id':id });
    $('#list-developpements').prepend(li);
    li.append(
        $('<div>', { class:'developpement-checkbox' }),

        $('<button>', { class:'developpement-delete', type:'button' }).append(
            $('<span>', { class:'glyphicon glyphicon-trash' })
        ),

        $('<button>', { class: 'developpement-edit developpement-'+id, type:'button' }).append(
            $('<span>', { class:'glyphicon glyphicon-pencil' })
        ),


        $('<div>').append(
            $('<a>', { href: window.location.protocol + "//" + window.location.hostname + '/developpements/'+id}).append(
                text
            )
        )
    );

    // Events
    li.find('.developpement-delete').click(deleteDeveloppement);
    li.find('.developpement-checkbox').click(lockDeveloppement);
    li.find('.developpement-edit').click(developpementsAction('edit'));

    // Disabled option
    var select = $('#list-developpements select').prop('selectedIndex', 0);
    select.find('option[value="'+ id +'"]').prop('disabled', true);
    select.trigger('chosen:updated');

    // Update number of devs
    var nDevs = parseInt($('#developpements-counter').text(), 10) + 1;
    $('#developpements-counter').text(nDevs);
}



/*
 *
 * (AJAX) Delete a developpement
 *
 */
$('#list-developpements .developpement-delete').unbind().click(deleteDeveloppement);
function deleteDeveloppement(){
    var li = $(this).parent();
    var id = li.attr('data-id');

    var deletion = function(){
        // Hide the modal
        $('#modal-delete').modal('hide');

        // Delete from bdd
        $.post(couplageAjaxUrl + '/developpement', { action:'delete', id:id });

        // Delete from list of devs + actu select
        var select = li.closest('ul').find('select');
        select.find('option[value="'+id+'"]').attr('disabled', false);
        select.trigger('chosen:updated');
        li.remove();

        // Delete from lecon
        $('.lecon li[data-id="'+id+'"]').each(function(index, item){
            var li = $(this);
            var select = li.closest('ul').find('select');
            select.find('option[value="'+id+'"]').attr('disabled', false);
            select.trigger('chosen:updated');
            li.remove();
        });

        // Update number of devs
        var nDevs = parseInt($('#developpements-counter').text(), 10) - 1;
        $('#developpements-counter').text(nDevs);
    };

    // Call bdd to test if its safe or not
    $.post(couplageAjaxUrl + '/developpement', { action:'testDelete', id:id })
    .done(function(data){
        if(data.safe)
            deletion();
        else {
            $('#modal-delete').modal('show');
            $('#modal-delete button[type="submit"]').unbind().click(deletion);
        }
    });
}


$('#developpement .developpement-delete').click(deleteDeveloppementFromDev);
function deleteDeveloppementFromDev(){
    var id = $(this).attr('data-id');

    var deletion = function(){
        // Hide the modal
        $('#modal-developpement-delete').modal('hide');

        // Delete from bdd
        $.post(couplageAjaxUrl + '/developpement', { action:'delete', id:id })
        .done(function(){
            location.reload();
        });
    };

    // Call bdd to test if its safe or not
    $.post(couplageAjaxUrl + '/developpement', { action:'testDelete', id:id })
    .done(function(data){
        if(data.safe)
            deletion();
        else {
            $('#modal-developpement-delete').modal('show');
            $('#modal-developpement-delete button[type="submit"]').unbind().click(deletion);
        }
    });
}



/*
 *
 * (AJAX) (Un)lock a developpement in the couplage
 *
 */
$('#list-developpements li .developpement-checkbox').click(lockDeveloppement);
$('.lecon li .developpement-lock').click(lockDeveloppement);
function lockDeveloppement(){
    var li = $(this).closest('li');
    var id = li.attr('data-id');
    if(li.hasClass('locked')){
        $('li[data-id="'+id+'"]').removeClass('locked');
        $('li[data-id="'+id+'"] .rating').removeClass('disabled');
        $('li[data-id="'+id+'"] .rating input').prop('disabled', false);
        $.post(couplageAjaxUrl + '/developpement', { 'id':id, 'action':'unlock' });
    }
    else {
        $('li[data-id="'+id+'"]').addClass('locked');
        $('li[data-id="'+id+'"] .rating').addClass('disabled');
        $('li[data-id="'+id+'"] .rating input').prop('disabled', true);
        $.post(couplageAjaxUrl + '/developpement', { 'id':id, 'action':'lock' });
    }

    this.blur();
}


$('#developpement #couplage .developpement-lock').click(function(){
    var dev = $('#couplage');
    var id = dev.attr('data-id');
    if(dev.hasClass('locked')){
        dev.removeClass('locked');
        $('li[data-id="'+id+'"] .rating').removeClass('disabled');
        $('li[data-id="'+id+'"] .rating input').prop('disabled', false);
        $.post(couplageAjaxUrl + '/developpement', { 'id':id, 'action':'unlock' });
    }
    else {
        dev.addClass('locked');
        $('li[data-id="'+id+'"] .rating').addClass('disabled');
        $('li[data-id="'+id+'"] .rating input').prop('disabled', true);
        $.post(couplageAjaxUrl + '/developpement', { 'id':id, 'action':'lock' });
    }
    this.blur();
});


/*
 * Update the display of a developpement according to its votes
 */
function updateDeveloppement(id, text, data){
    // Delete previous votes
    var checkedIds = [];
    $('.lecon ul.couverture li[data-id="'+id+'"]').each(function(index, item){
        var li = $(this);
        if(li.hasClass('checked') && typeof data.votes[li.attr('data-lecon-id')] != undefined)
            checkedIds.push(li.attr('data-lecon-id'));
        var select = li.closest('ul').find('select');
        select.find('option[value="'+id+'"]').attr('disabled', false);
        select.trigger('chosen:updated');
        li.remove();
    });

    // Create new ones
    $.each(data.votes, function(lid, v){
        var value = data.votes[lid].qualite;
        var name = 'couverture_' + lid + '['+ id +'][qualite]';
        var checked = (checkedIds.indexOf(lid) == -1)? '' : ' checked';

        $('#lecon-' + lid + ' ul.couverture').prepend(
            $('<li>', { class:'list-group-item couverture-rating couverture-rating-'+ value + checked, 'data-id':id, 'data-lecon-id':lid }).append(
                $('<div>', { class:'couverture-checkbox' + checked, id:'couverture_'+lid+'_'+id}),

                Rating.createRating(name, value, 5, false),

                $('<button>', { class:'delete', type:'button' }).append(
                    $('<span>', { class:'glyphicon glyphicon-trash' })
                ),

                $('<button>', { class: 'developpement-edit', type:'button' }).append(
                    $('<span>', { class:'glyphicon glyphicon-pencil' })
                ),

                $('<button>', { class: 'developpement-lock', type:'button' }).append(
                    $('<i>', { class:'fa' })
                ),


                $('<div>').append(
                    $('<a>', { href: window.location.protocol + "//" + window.location.hostname + '/developpements/'+id}).append(
                        text
                    )
                )
            )
        );

        var o = $(document.getElementById(name +'-rating-' + value)).prop('checked', true);
        var li = o.closest('li');
        li.find('.rating-input').unbind().change(changeRating);
        li.find('.couverture-checkbox').unbind().click(chooseDeveloppement);
        li.find('.delete').unbind().click(removeRating);
        li.find('.developpement-edit').unbind().click(developpementsAction('edit'));
        li.find('.developpement-lock').unbind().click(lockDeveloppement);


        // Disabled option
        var select = $('#lecon-' + lid + ' ul.couverture select').prop('selectedIndex', 0);
        select.find('option[value="'+ id +'"]').prop('disabled', true);
        select.trigger('chosen:updated');
    });
}






/*
 *
 * (AJAX) Commentaire modification for a lecon
 *
 */

// Autogrow textarea
$('.lecon textarea').autogrow();

$('.lecon textarea').focus(function(){
    $(this).parent().find('button').css('left', '-30px');
});

$('.lecon textarea').blur(function(){
    $(this).parent().find('button').css('left', '0px');
    $.post(couplageAjaxUrl + '/commentaires', { type:'lecon', id:$(this).attr('data-id'), value: $(this).val() });
});



/*
 *
 * (AJAX) (Un)cover a lecon
 *
 */
function updateStatus(o, v){ o.removeClass('state-0 state-1 state-2 state-3').addClass('state-' + v); }
$('.lecon .switcher2 .switcher-state').click(function(){
    var value  = $(this).attr('data-value');
    var div    = $(this).parent();
    var lecon  = div.closest('.lecon');
    var id     = lecon.attr('data-id');

    updateStatus($('#apercu-lecon-' + id), value);
    updateStatus(div, value);
    updateStatus(lecon, value);
    $.post(couplageAjaxUrl + '/couverture', { lecon_id:id, action: 'status', status: value });
});



/*
 *
 * (AJAX) Change rating in list developpement of lecon
 *
 */
$('li.couverture-rating .rating-input').change(changeRating);
function changeRating(){
    var li = $(this).closest('li');
    li.removeClass('couverture-rating-1 couverture-rating-2 couverture-rating-3 couverture-rating-4 couverture-rating-5');
    if(this.checked){
        li.addClass('couverture-rating-' + this.value);
        $.post(couplageAjaxUrl + '/developpement', {
            'action'    : 'rating',
            'id'        : li.attr('data-id'),
            'lecon-id'  : li.attr('data-lecon-id'),
            'value'     : this.value
        });
    }
}


$('.lecon li.couverture-rating .delete').click(removeRating('lecon'));
$('.developpement li.couverture-rating .delete').click(removeRating('developpement'));
function removeRating(type){
    return function(){
        var li = $(this).closest('li');
        $.post(couplageAjaxUrl + '/developpement', {
            'action'    : 'rating',
            'id'        : li.attr('data-id'),
            'lecon-id'  : li.attr('data-lecon-id'),
            'value'     : 0
        }).done(function(){
            var select = li.parent().find('select');
            select.find('option[value="'+ (type == 'lecon'? li.attr('data-id') : li.attr('data-lecon-id') )+'"]').prop('disabled', false);
            select.trigger('chosen:updated');

            li.remove();
        });
    }
}


/*
 *
 * (AJAX) (Un)pick a developpement in a given lecon
 *
 */
$('ul li .couverture-checkbox').click(chooseDeveloppement);
function chooseDeveloppement(){
    var o = $(this);
    var ids = o.attr('id').split('_');
    if(o.hasClass('checked')){
        o.removeClass('checked');
        o.closest('li').removeClass('checked');
        $.post(couplageAjaxUrl + '/couverture', { 'lecon_id':ids[1], 'developpement_id':ids[2], 'action':'delete' });
    }
    else {
        o.addClass('checked');
        o.closest('li').addClass('checked');
        $.post(couplageAjaxUrl + '/couverture', { 'lecon_id':ids[1], 'developpement_id':ids[2], 'action':'add' });
    }
}




/*
 *
 * (AJAX) Commentaire modification for a developpement
 *
 */

// Autogrow textarea
$('.developpement textarea').autogrow();

$('.developpement textarea').focus(function(){
    $(this).parent().find('button').css('left', '-30px');
});

$('.developpement textarea').blur(function(){
    $(this).parent().find('button').css('left', '0px');
    $.post(couplageAjaxUrl + '/commentaires', { type:'developpement', id:$(this).attr('data-id'), value: $(this).val() });
});


/*
 *
 * (AJAX) Add a lecon in the list of developpement
 *
 */
 $('.developpement .couverture-add-btn').click(addLecon);
function addLecon(){
    var select = this.nextElementSibling;
    var lid    = select[select.selectedIndex].value;
    var id     = this.getAttribute('data-id');
    var text   = select[select.selectedIndex].innerHTML;
    var name = 'couverture_' + lid + '['+ id +'][qualite]';
    var value  = 1;
    var ul = $(this).closest('ul');

    if(lid == ''){
        alert("Champ vide.");
        return false;
    }

    $.post(couplageAjaxUrl + '/developpement', {
        'action'    : 'rating',
        'id'        : id,
        'lecon-id'  : lid,
        'value'     : value
    }).done(function(){
        ul.prepend(
            $('<li>', { class:'list-group-item couverture-rating couverture-rating-'+ value, 'data-id':id, 'data-lecon-id':lid }).append(
                $('<div>', { class:'couverture-checkbox', id:'couverture_'+lid+'_'+id}),

                Rating.createRating(name, value, 5, false),

                $('<button>', { class:'delete', type:'button' }).append(
                    $('<span>', { class:'glyphicon glyphicon-trash' })
                ),

                $('<div>').append(
                    $('<a>', { href: window.location.protocol + "//" + window.location.hostname + '/lecons/'+lid}).append(
                        text
                    )
                )
            )
        );

        var o = $(document.getElementById(name +'-rating-' + value)).prop('checked', true);
        var li = o.closest('li');
        li.find('.rating-input').unbind().change(changeRating);
        li.find('.couverture-checkbox').unbind().click(chooseDeveloppement);
        li.find('delete').unbind().click(removeRating);


        // Disabled option
        var select2 = $(select).prop('selectedIndex', 0);
        select2.find('option[value="'+ lid +'"]').prop('disabled', true);
        select2.trigger('chosen:updated');
    });
}
});


/*
 *
 * (AJAX) Change references of a lecon and a dev
 *
 */
$(".lecons-references").on('change', function(evt, params){
    $.post(couplageAjaxUrl + '/references', { type:'lecon', id:$(this).attr('data-id'), value: $(this).val() });
});

$(".developpements-references").on('change', function(evt, params){
    $.post(couplageAjaxUrl + '/references', { type:'developpement', id:$(this).attr('data-id'), value: $(this).val() });
});



/*
 *
 * Explications
 *
 */

$(function(){
    $('#carousel-couplage-explications').on('slid.bs.carousel', function() {
        var $this = $(this);

        $this.children('.carousel-control').show();

        if($('.carousel-inner .item:first').hasClass('active'))
            $this.children('.left.carousel-control').hide();
        else if($('.carousel-inner .item:last').hasClass('active'))
            $this.children('.right.carousel-control').hide();
    });

    $('#carousel-couplage-explications .left.carousel-control').hide();


    if(typeof Cookies.get('couplage_explications') == "undefined"){
        $("#modal-couplage-pre-explications").modal('show');
        $("#modal-couplage-pre-explications .btn-primary").click(function(){
            $("#modal-couplage-pre-explications").modal('hide');
            $("#modal-couplage-explications").modal('show');
            $('#carousel-couplage-explications').carousel(0);
        });

        Cookies.set('couplage_explications', '1', { expires:30});
    }

});





/*
 *
 * Export
 *
 */

$('#modal-export .btn-primary').click(function(){
    var id = this.getAttribute('data-id');
    $.post(couplageAjaxUrl + '/exportSettings', $('#modal-export form').serialize())
    .done(function(data){
        window.location = "/pdf/couplage/" + id;
    });
});
