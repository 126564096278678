$(document).ready(function() {
    // Developpements datatable
    $('#developpements-table').DataTable({
        "language": {
            "lengthMenu": "Afficher _MENU_ développements",
            "search": "<span class='glyphicon glyphicon-search'></span>",
            "paginate": {
                "next":      "Suivant",
                "previous":  "Précédent"
            },
            "zeroRecords": "Pas de développement correspondant.",
        },
        "info": false,
        "dom": '<"table-header"<"container"lf>>t<"table-footer container"ip>',
        "drawCallback": function(settings) {
            MathJax.Hub.Queue(["Typeset",MathJax.Hub]); 
        },        
        "lengthMenu": [[10, 15, 20, 30, 50, -1], [10, 15, 20, 30, 50, "Tous"]],
        "stateSave": true,
    });


    // References datatable
    $('#references-table').DataTable({
        "language": {
            "lengthMenu": "Afficher _MENU_ références",
            "search": "<span class='glyphicon glyphicon-search'></span>",
            "paginate": {
                "next":      "Suivant",
                "previous":  "Précédent"
            },
            "zeroRecords": "Pas de développement correspondant.",
        },
        "info": false,
        "dom": '<".table-header"<"container"lf>>t<".table-footer container"ip>',
        "drawCallback": function(settings) {
            MathJax.Hub.Queue(["Typeset",MathJax.Hub]); 
        },        
        "lengthMenu": [[10, 15, 20, 30, 50, -1], [10, 15, 20, 30, 50, "Tous"]],
        "stateSave": true,
    });


    // References datatable
    $('#couplages-table').DataTable({
        "language": {
            "lengthMenu": "Afficher _MENU_ couplages",
            "search": "<span class='glyphicon glyphicon-search'></span>",
            "paginate": {
                "next":      "Suivant",
                "previous":  "Précédent"
            },
            "zeroRecords": "Pas de couplages correspondant.",
        },
        "info": false,
        "dom": '<".table-header"<"container"lf>>t<".table-footer container"ip>',      
        "lengthMenu": [[10, 15, 20, 30, 50, -1], [10, 15, 20, 30, 50, "Tous"]],
        "stateSave": true,
    });


    // Lecons datatable
    if(document.getElementById("lecons-container") == null)
        return;

    var leconsSelector = 0;
    $.fn.dataTable.ext.search.push(
        function(oSettings, aData, iDataIndex) {
            if(leconsSelector == 0)
                return true;

            return aData[1] == leconsSelector;
        }
    );

    var fnSelectors = function(i){ 
        return function ( e, dt, node, config ) {
            leconsSelector = i;
            leconsTable.draw();
            dt.buttons().active(false);
            dt.button(i).active(true);
        };
    }

    var leconsTable = $('#lecons-table').DataTable({
        language: {
            search: "<span class='glyphicon glyphicon-search'></span>",
            zeroRecords: "Pas de leçons correspondantes.",
        },
        buttons: [
            {
                text: 'Tous',
                action: fnSelectors(0)
            },
            {
                text: 'Algèbre',
                action: fnSelectors(1)
            },
            {
                text: 'Analyse',
                action: fnSelectors(2)
            },
            {
                text: 'Informatique',
                action: fnSelectors(3)
            }
        ],
        paging: false,
        ordering: false,
        info: false,
        dom: '<".table-header"<"container"Bf>>t<".table-footer container"ip>',      
        stateSave: false,
    });

    leconsTable.button(0).active(true);
});