(function(){
if(document.getElementById("rekasator-choix") == null)
   return;


// Tooltips
$(document).ready(function() {
    $('.strip:first-of-type .form-group label').each(function(index){
        var message;
        if(index == 0) message = "Qualité moyenne voulue sur les recasages d'une leçon.";
        if(index == 1) message = "Si ce paramètre est élevé, les développements qui se recasent dans beaucoup des leçons choisies seront favorisés.";
        if(index == 2) message = "Nombre d'essais maximum.";
        if(index == 3) message = "Choisir les leçons à couvrir.";

        $(this).tooltip({
            placement:'auto right',
            title:message
        })
    });
});


// Recasator form
$(document).ready(function() {    
    var setChoix = function(choix){
        $('#rekasator-choix').prop('value', choix);
    }

    // Checkboxes
    $('#rekasator-form .form-group input[type=checkbox]').change(function(){
        if($(this).is(':checked'))
            $(this).closest('.checkbox-inline, .checkbox').addClass('checked');
        else
            $(this).closest('.checkbox-inline, .checkbox').removeClass('checked');
        setChoix("Personnalisé");
    });
    $('#rekasator-form .form-group input[type=checkbox]').change();


    /*## Sélection rapide ##*/
    var quickSelect = function(selector, msg){
        return function(){
            $('#rekasator-lecons input[type=checkbox]').prop('checked', false).change();
            $('#rekasator-lecons input[type=checkbox]' + selector).prop('checked', true).change();
            setChoix(msg);
            $(this).closest('ul').dropdown('toggle');
            return false;
        };
    };

    $('#rekasator-toutes').on('click', quickSelect('', 'Toutes les leçons'));
    $('#rekasator-aucune').on('click', quickSelect('.none', 'Aucune leçon'));
    $('#rekasator-maths' ).on('click', quickSelect('.maths', 'Toutes les leçons de mathématiques'));
    $('#rekasator-info'  ).on('click', quickSelect('.info' , "Toutes les leçons d'informatique"));
    $('#rekasator-maths-info').on('click', quickSelect('.maths-info', "Toutes les leçons de mathématiques option D"));





    /*## Soumission du formulaire ##*/
    $('#rekasator-form input[type=submit]').prop('disabled', false).val('Générer');

    $('#rekasator-form').submit(function(event){
        // Récupération des champs du formulaire
        var lecons = {};
        $("input[name^='lecon']:checked").each(function() {
           lecons[this.getAttribute('name').substr(7,3)] = $(this).val();
        });

        var data = {
            'qualite'    : $('input[name=qualite]').val(),
            'recasages'  : $('input[name=recasages]').val(),
            'nbr_essais' : $('input[name=nbr_essais]').val(),
            'lecons'     : lecons
        };

        // On désactive le bouton de soumission
        $('#rekasator-form input[type=submit]').prop('disabled', true).val('Chargement...');


        // On envoie la requête AJAX
        $.post({
            url      : this.getAttribute('action'),
            data     : data,
            dataType : 'json',
            encode   : true,

            // Si tout est bon
            success  : function(data) {
                //console.log(data);


                /*### Calcul de stats sur le résultat ###*/
                var nLecons = 0;
                var nLeconsRestantes = 0;
                var aDevs = [];
                var nQualite = 0;
                var nQualiteNbr = 0;
                for(var lid in data.recasages){
                    nLecons++;
                    if(data.recasages[lid].length < 2)
                        nLeconsRestantes++;

                    for(var i = 0; i < data.recasages[lid].length; i++)
                    {
                        aDevs[data.recasages[lid][i].developpement.id] = true;
                        nQualite += parseInt(data.recasages[lid][i].qualite, 10);
                        nQualiteNbr++;
                    }
                }
                nQualite = Math.round(100 * nQualite / nQualiteNbr) / 100;


                /*#### Affichage ####*/

                // Création du container
                var container = $(document.createElement('div'))
                    .addClass('container').addClass('form-horizontal')
                    .appendTo($("#rekasator-resultats").empty());


                // Résumé + stats
                var message = (nLeconsRestantes > 0)?
                        ((nLecons - nLeconsRestantes) + " leçons couvertes sur " + nLecons + " leçons. (Les leçons non-couvertes sont en rouge)")
                    :   ("Les " + nLecons +" leçons sont couvertes.");

                container.append(`
                    <div class="form-group form-group-lg">
                        <label class="col-md-2 control-label">Résultats :</label>
                        <div class="col-md-10">
                            <p class="form-control-static">` + message + `</p>
                            <p class="form-control-static">Nombre de développements utilisés : ` + Object.keys(aDevs).length + ` </p>
                            <p class="form-control-static">Qualité moyenne : ` + nQualite + `</p>
                        </div>
                    </div>`);


                /*#### Recasages en détails ####*/
                // Fonction pour générer un rating
                var rating = function(rank, scale = 5){
                    var result = '<span class="display-rating">';
                    for(var i = 0; i < rank; i++)
                        result += '<span class="glyphicon glyphicon-star"></span>';
                    for(var i = rank; i < scale; i++)
                        result += '<span class="glyphicon glyphicon-star-empty"></span>';
                    result += '</span>';

                    return result;
                }

                // Itération
                for(var lid in data.lecons){
                    // Recasages
                    var rec = "";
                    for(var i = 0; i < data.recasages[lid].length; i++){
                        rec += `
                        <li>
                            <a href="/developpements/`+ data.recasages[lid][i].developpement.id +`">
                            `+ rating(data.recasages[lid][i].qualite) +`
                            `+ data.recasages[lid][i].developpement.nom +`
                            </a>
                        </li>`
                    }

                    container.append(`
                    <div class="lecon` + ((data.recasages[lid].length < 2)? ' not-covered' : '') + ` form-group form-group-lg">
                        <label class="control-label">` + data.lecons[lid].numero + ` ‒</label>
                        <div>
                            <p>` + data.lecons[lid].nom + `</p>
                            <ul>`+ rec +`</ul>
                        </div>
                    </div>`);
                }

                // On relance MathJax
                MathJax.Hub.Queue(["Typeset",MathJax.Hub, container.get()]);

                // On scroll sur les résultats
                var documentBody = document.documentElement || document.body;
                $(documentBody).animate( {scrollTop: container.offset().top - 68}, 1000);

                // On réactive le bouton de soumission
                $('#rekasator-form input[type=submit]').prop('disabled', false).val('Générer');

            }
        });

        return false;
    });
});

})();