(function(){
if(document.getElementById("kiviat-chart") == null)
   return;

/*####################################
######################################
############ RENDERER  ###############
######################################
####################################*/
var KiviatChart = (function()
{
    // Parameters
    var CENTER_X;
    var CENTER_Y;
    var RADIUS;
    var RADIUS_MIN  = 10;
    var OFFSET_LINE = 28;
    var OFFSET_TEXT = 3;
    var SCALE = 5;

    var STROKE_COLORS = ["rgba(255,0,0,1.0)", "rgba(75,126,253,1.0)", "rgba(220,220,220,1.0)"];
    var FILL_COLORS   = ["rgba(255,0,0,0.4)", "rgba(75,126,253,0.4)", "rgba(220,220,220,0.7)"];


    // Utility variables
    var canvas = document.getElementById("kiviat-chart");
    var ctx = canvas.getContext("2d");

    var labels = [];
    var N;

    var nbrPlotted = 0;



    /* Get the coordinates on the circle*/
    function coords(a, i, offset)
    {
        var r = (a == 0)? RADIUS_MIN : (a*RADIUS/SCALE);
        r += (offset)? offset : 0;

        return {
            x : CENTER_X + r*Math.cos(2*i*Math.PI/N),
            y : CENTER_Y + r*Math.sin(2*i*Math.PI/N)
        };
    }

    /*#################################
    ######## Drawing functions ########
    #################################*/
    // Clear
    function clear()
    {
        ctx.clearRect(0,0,canvas.width,canvas.height);
        nbrPlotted = 0;
    }


    // Draw a radius at given angle
    function drawRadius(i)
    {
        var s = coords(0, i),
            t = coords(SCALE, i, OFFSET_LINE);
        ctx.beginPath();
        ctx.strokeStyle = "gray";
        ctx.moveTo(s.x, s.y);
        ctx.lineTo(t.x, t.y);
        ctx.stroke();
    }


    // Draw the background grid
    function drawGrid()
    {
        for(var i = 0; i < N; i++)
        {
            drawRadius(i);

            for(var j = 0; j <= SCALE; j++)
            {
                var s = coords(j,i),
                    t = coords(j,i+1);
                ctx.beginPath();
                ctx.strokeStyle = (j == SCALE)? "black" : "gray";
                ctx.moveTo(s.x,s.y);
                ctx.lineTo(t.x,t.y);
                ctx.stroke();
            }
        }
    }


    // Draw the labels
    function drawLabels()
    {
        ctx.textBaseline = "top";
        ctx.font         = "16px ComputerModernSerif";
        ctx.fillStyle    = "#000000";

        for(var i = 0; i < N; i++)
        {
            ctx.save();
            ctx.translate(CENTER_X,CENTER_Y);

            var angle = i*2*Math.PI/N;
            if(Math.PI/2 < angle && angle < 4*Math.PI/3)
            {
                ctx.textAlign = "right";
                ctx.rotate(i*2*Math.PI/N + Math.PI);
                ctx.fillText(labels[i], -RADIUS - OFFSET_TEXT, -16);
            }
            else
            {
                ctx.textAlign = "left";
                ctx.rotate(i*2*Math.PI/N);
                ctx.fillText(labels[i],  RADIUS + OFFSET_TEXT, -16);
            }

            ctx.restore();
        }
    }


    /*#### Draw the data ####*/
    this.lastPlotted = [];
    function drawData(data)
    {
        this.lastPlotted = data;

        /* Draw shade */
        ctx.strokeStyle = STROKE_COLORS[nbrPlotted];
        ctx.fillStyle   = FILL_COLORS[nbrPlotted];
        ctx.beginPath();

        // First point (= last one to make a loop)
        var s = coords(data[N-1], N-1);
        ctx.moveTo(s.x, s.y);

        for(var i = 0; i < N; i++)
        {
            s = coords(data[i], i);
            ctx.lineTo(s.x, s.y);
        }
        ctx.stroke();
        ctx.fill();


        /* Draw points */
        ctx.fillStyle = STROKE_COLORS[nbrPlotted];
        for(var i = 0; i < N; i++)
        {
            s = coords(data[i], i);
            ctx.beginPath();
            ctx.arc(s.x, s.y, 3, 0, 2*Math.PI);
            ctx.fill();
        }
        
    }


    /*##########################
    ######## Responsive ########
    ##########################*/
    /* Set parameters depending on size of canvas */
    function setParameters()
    {
        canvas.width = $(canvas).width();
        canvas.height = $(canvas).height();
        CENTER_X = $(canvas).width()/2;
        CENTER_Y = $(canvas).height()/2;
        RADIUS = Math.min(CENTER_X, CENTER_Y) - 50;
    }
    setParameters();

    // Refresh the plotting when window is resized
    $(window).on('resize', (function(o){
        return function(){
           setParameters();
           o.plot(o.lastPlotted);
        };
    })(this));


    /*##########################
    ###### Public method #######
    ##########################*/
    this.setLabels = function(l)
    {
        labels = l;
        N = labels.length;
        clear();
        drawGrid();
        drawLabels();
    };

    this.plot = function(data, reset)
    {
        reset = (typeof reset == "undefined")? true : reset;

        if(reset)
        {
            clear();
            drawGrid();
            drawLabels();
        }

        drawData(data);
        nbrPlotted++;
    };




    return this;
})();



/*####################################
######################################
########### DEVS LIST  ###############
######################################
####################################*/
var FilterDevs = (function()
{
    this.lastFilter = [];
    var devsList = document.getElementById("kiviat-developpements");    
    var aScores  = [];
    var oSelected, iSelected = -1;

    // Load devs from the page and attach event
    var devs = [];
    var aDevs = devsList.getElementsByTagName("li");
    for(var i = 0; i < aDevs.length; i++)
    {
        var num = parseInt(aDevs[i].id.substr(14), 10);
        devs[num] = num;
    }


    // Load leçons from ajax call
    var lecons = [];
    $.ajax({
        url: window.location.pathname + "/data",
        async: true,
        dataType: 'json',
        success: function (data) {
            lecons = data;
        }
    });

    // Filter type
    this.ftype = 0;
    $("#kiviat-mode").click((function(o){
        return function(){
            if($(this).hasClass('unite'))
            {
                $(this).removeClass('unite'); 
                $(this).addClass('intersect');
                o.ftype = 1; 
            }
            else
            {
                $(this).addClass('unite'); 
                $(this).removeClass('intersect');
                o.ftype = 0; 
            }
            o.refreshDevs(o.lastFilter);

            return false;
        };
    })(this));


    /* Compute the scores of devs according to a filter */
    function computeScores(filter)
    {
        // Init the scores
        for(var i in devs)
        {
            aScores[i] = {
                total:0,
                qualite:[]
            };
    
            for(var j = 0; j < filter.length; j++)
                aScores[i].qualite[j] = 0;
        }

        // Go through selected lecons
        for(var i = 0; i < filter.length; i++)
        {
            var lecon = lecons[filter[i]];
            for(var j = 0; j < lecon.recasages.length; j++)
            {
                var c = lecon.recasages[j];
                aScores[c.developpement].total += c.qualite;
                aScores[c.developpement].qualite[i] = c.qualite;
            }
        }
    }


    /* Filter the devs according to given criteria */
    function filterDevs()
    {
        var tmp = [];
        for(var i in devs)
        {
            // Filter : OR
            if(this.ftype == 0)
            {
                if(aScores[i].total > 0)
                    tmp.push(i);
            }

            // Filter : AND
            if(this.ftype == 1)
            {
                var suited = (aScores[i].qualite.length > 0);
                for(var j = 0; suited && j < aScores[i].qualite.length; j++)
                    suited = (aScores[i].qualite[j] > 0);

                if(suited)
                    tmp.push(i);
            }
        }

        return tmp;
    }


    /* Compare the devs according to the scores */
    function compareDevs(a,b)
    {
        return (aScores[a].total < aScores[b].total); 
    }


    /*#### Main function ####*/
    this.iSelected = 0;
    this.oSelected = null;
    this.refreshDevs = function(filter)
    {
        // Compute score according to filter
        computeScores(filter);
        lastFilter = filter;

        // Filter devs according to given criterion
        var tmp = filterDevs();

        // Sort devs
        tmp.sort(compareDevs);

        /* Create DOM elements */
        devsList.removeChild(devsList.getElementsByTagName("ul")[1]);
        var ul = document.createElement("ul");
        devsList.appendChild(ul);

        for(var i = 0; i < tmp.length; i++)
        {
            var li = document.getElementById('developpement_' + tmp[i]).cloneNode(true);
            ul.appendChild(li);
            if(i == 0)
            {
                li.classList.add("selected");
                this.iSelected = tmp[0];
                this.oSelected = li;
            }


            // Add events
            li.onclick = (function(i, o){
                return function(){ 
                    KiviatChart.plot(aScores[i].qualite);

                    o.oSelected.classList.remove("selected");
                    o.iSelected = i;
                    o.oSelected = this;
                    o.oSelected.classList.add("selected");
                }
            })(tmp[i], this);

            li.onmouseover = (function(i){
                return function(){ KiviatChart.plot(aScores[i].qualite, false); }
            })(tmp[i]);

            li.onmouseout = (function(o){
                return function(){ 
                    if(o.iSelected != -1)
                        KiviatChart.plot(aScores[o.iSelected].qualite);
                }
            })(this);
        }

        KiviatChart.plot(aScores[tmp[0]].qualite);
    };

    return this;
})();



/*####################################
######################################
######### LECONS CHOOSER  ############
######################################
####################################*/
var LeconsChooser = (function(){
    // Params
    this.disabled    = false;
    this.autoRefresh = true;

    // DOM elements we use
    var chooser = document.getElementById("kiviat-lecons"),
        aLinks  = chooser.getElementsByTagName("a");

    // Refresh list of lecons choosed
    this.refreshLecons = function()
    {
        var labels = [];
        for(var i = 0; i < aLinks.length; i++)
            if(aLinks[i].classList.contains("selected"))
                labels.push(parseInt(aLinks[i].innerHTML, 10));

        KiviatChart.setLabels(labels);
        FilterDevs.refreshDevs(labels);
    };


    for(var i = 0; i < aLinks.length; i++)
        aLinks[i].onclick = (function(o){ return function(){
            if(o.disabled)
                return false;

            this.classList.toggle("selected"); 
            if(o.autoRefresh)
                o.refreshLecons();

            return false;
        };})(this);

    return this;
})();



/*####################################
######################################
########### GUIDED TOUR  #############
######################################
####################################*/
// Instance the tour
var KiviatTour = new Tour({
    backdrop: true,
    onEnd: function(){
        LeconsChooser.disabled = false;
    },
    onNext: function(tour){
        var placements = ($(window).width() > 992)? 
             ['center', 'right', 'left', 'right', 'left', 'bottom']
            :['center', 'bottom', 'top', 'top', 'top', 'right'];

        for(var i = 0; i < tour._options.steps.length; i++)
            tour._options.steps[i].placement = placements[i];
    },
    template:`
        <div class='popover tour'>
          <div class='arrow'></div>
          <button type="button" class="close" aria-label="Close" data-role='end'><span aria-hidden="true">&times;</span></button>
          <h3 class='popover-title'></h3>

          <div class='popover-content'></div>
          <div class='popover-navigation pull-right'>
            <button class='btn btn-default' data-role='prev'>« Précédent</button>
            <span data-role='separator'></span>
            <button class='btn btn-default' data-role='next'>Suivant »</button>
          </div>
        </div>`,

    steps: [
    {
        orphan: true,
        placement: "center",
        title: "Kiviat",
        content: "Le diagramme de Kiviat est un outil pratique lorsqu'il vous manque des développements pour couvrir des leçons.",
        template:`
            <div class='popover tour'>
              <div class='arrow'></div>
              <button type="button" class="close" aria-label="Close" data-role='end'><span aria-hidden="true">&times;</span></button>
              <h3 class='popover-title'></h3>

              <div class='popover-content'></div>
              <div class='popover-navigation pull-right'>
                <button class='btn btn-default' data-role='next'>Suivant »</button>
              </div>
            </div>`,
        onShow: function(){
            LeconsChooser.disabled = true;
        },
    },
    {
        element: "#kiviat-lecons",
        placement: "right",
        title: "Leçons",
        content: "Dans ce menu, on peut choisir les leçons pour lesquelles il nous manque des développements.",
        onShown: function(){
            $("#kiviat-liens-121").addClass("selected");
            $("#kiviat-liens-123").addClass("selected");
            $("#kiviat-liens-125").addClass("selected");
            $("#kiviat-liens-141").addClass("selected");
        }
    },
    {
        element: "#kiviat-developpements",
        title: "Développements",
        placement: "left",
        content: "On obtient alors une liste de développements qui se recasent dans ces leçons, triés par somme des qualités des recasages.",
        onShow: function(){
            LeconsChooser.refreshLecons();
        }
    },
    {
        element: "#kiviat-chart",
        title: "Diagramme",
        placement: "right",
        content: "Lorsqu'on sélectionne un développement (click dessus), on peut voir plus en détails ses recasages dans les leçons sélectionnées à l'aide de ce diagramme.",
    },
    {
        element: "#kiviat-developpements",
        title: "Comparaison",
        placement: "left",
        content: "On peut également comparer le développement à un autre en survolant le deuxième. Les flèches sur la droite vous permettent d'accéder aux pages des développements.",
    },
    {
        element: "#kiviat-mode",
        title: "Mode",
        placement: "bottom",
        onShow: function(){
            $('#kiviat-mode').trigger('click');
        },
        onHidden: function(){
            $('#kiviat-mode').trigger('click');            
        },
        content: "Il existe deux modes de fonctionnements. Dans le premier (union), un développement apparait dans la liste de droite s'il se recase dans au moins une leçon. Dans le deuxième mode (intersection), un développement apparait s'il se recase dans chacune des leçons.",
        template:`
            <div class='popover tour'>
              <div class='arrow'></div>
              <button type="button" class="close" aria-label="Close" data-role='end'><span aria-hidden="true">&times;</span></button>
              <h3 class='popover-title'></h3>

              <div class='popover-content'></div>
              <div class='popover-navigation pull-right'>
                <button class='btn btn-default' data-role='prev'>« Précédent</button>
                <span data-role='separator'></span>
                <button class='btn btn-default' data-role='end'>Fin</button>
              </div>
            </div>`,
    },
]});

KiviatTour.init();
KiviatTour.start();
$("#kiviat-help").click(function(){
    KiviatTour.restart();
});


})();
