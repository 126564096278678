var Rating = (function(){

// Create a rating control
this.createRating = function(name, value, scale, zero) {
    value = (typeof value === 'undefined') ? 0 : value;
    scale = (typeof scale === 'undefined') ? 5 : parseInt(scale);
    zero  = (typeof zero  === 'undefined') ? 0 : zero;

    var span = document.createElement('div');
    span.setAttribute('class', 'rating rating-scale-'+ (scale + (zero? 1 : 0)));

    for(i = scale; i > 0; i--)
    {
        var id = name + '-rating-' + i;

        // Setting up the input
        var input = document.createElement('input');
        input.setAttribute('type',  'radio');
        input.setAttribute('class', 'rating-input');
        input.setAttribute('id',    id);
        input.setAttribute('name',  name);
        input.setAttribute('value', i);
        if(value == i)
            input.checked = true;
        span.appendChild(input);

        // Setting up the label
        var label = document.createElement('label');
        label.setAttribute('for', id);
        label.setAttribute('class', 'rating-star');
        label.appendChild(document.createElement('span'));
        span.appendChild(label);
    }

    if(zero == 1)
    {
        var id = name + '-rating-0';

        // Setting up the input
        var input = document.createElement('input');
        input.setAttribute('type',  'radio');
        input.setAttribute('class', 'rating-input');
        input.setAttribute('id',    id);
        input.setAttribute('name',  name);
        input.setAttribute('value', 0);
        if(value == 0)
            input.checked = true;
        span.appendChild(input);

        // Setting up the label
        var label = document.createElement('label');
        label.setAttribute('for', id);
        label.setAttribute('class', 'rating-zero');
        span.appendChild(label);

        var emptyset = document.createElement('span');
        emptyset.appendChild(document.createTextNode("∅"));
        label.appendChild(emptyset);
    }

    return span;
};


// Add a rating list element
this.addRating = function(){
    var select = this.nextElementSibling;
    var vid    = select[select.selectedIndex].value;
    var vtext  = select[select.selectedIndex].innerHTML;
    if(vid == '')
    {
        alert("Champ vide.");
        return false;
    }

    // Creating new rating
    var li = document.createElement('li');
    li.setAttribute('class', 'list-group-item rating-item');
    li.setAttribute('data-id', vid);

    var scale = this.getAttribute('data-scale');
    if(scale == 0)
    {
        var name = this.getAttribute('data-name') + '['+ vid +']';
        var hinput = document.createElement('input');
        hinput.setAttribute('type', 'hidden');
        hinput.setAttribute('name', name);
        hinput.setAttribute('value', 1);
        li.appendChild(hinput);
    }
    else
    {
        var zero = this.getAttribute('data-zero');
        var name  = this.getAttribute('data-name') + '['+ vid +']['+ this.getAttribute('data-field') +']';
        var rating = Rating.createRating(name, 1, scale, zero);
        li.appendChild(rating);
    }

    // Link to delete ranking
    var a = document.createElement('button');
    a.setAttribute('class', 'btn-link rating-delete');
    a.onclick = this.deleteRating;

    var glyphicon = document.createElement('span');
    glyphicon.setAttribute('class', 'glyphicon glyphicon-trash');
    a.appendChild(glyphicon);

    li.appendChild(a);

    // Content
    var div = document.createElement('div');
    li.appendChild(div);
    $(div).append(vtext);

    // Attach to the list
    var ali = this.parentNode.parentNode;
    ali.parentNode.insertBefore(li, ali);

    // Disabled option
    select[select.selectedIndex].setAttribute('disabled', 'disabled');
    select.selectedIndex = 0;
    $(select).trigger('chosen:updated');
};


// Delete a list element
this.deleteRating = function() {
    var li  = this.parentNode;
    var vid = li.getAttribute('data-id');
    var ul = li.parentNode;

    // Remove the list-elem
    ul.removeChild(li);

    // Update the list
    var select = ul.getElementsByClassName('chosen-select')[0];
    var option = select.querySelectorAll('option[value="'+ vid + '"]')[0];
    option.disabled = false;
    $(select).trigger('chosen:updated');

    return false;
};
$('.rating-delete').click(this.deleteRating);



$('.rating-add-btn').click(this.addRating);
this.add = function(o){
    o.find('.rating-add-btn').click(this.addRating);
    o.find('.rating-delete').click(this.deleteRating);
};


return this;
})();
